import css from './PDFBox.module.scss'
import React from 'react'

import Img from 'components/Media/Img'
import { useUrlCloudfront } from 'hooks/useEnv'

interface IProps {
  title: string
  link: string
  filename: string
}

const PDFBox: React.FC<IProps> = (props) => {
  const { title, link, filename } = props

  const urlCloudfront = useUrlCloudfront()

  const fileUrl = `${urlCloudfront}${link}`

  const handleDownload = async () => {
    try {
      const response = await fetch(fileUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()

      link.parentNode?.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      // handle error
    }
  }

  return (
    <div className={css.pdfbox_container}>
      <div className={css.pdf_icon}>
        <Img alt="" src="/icons/pdf_file.svg" />
      </div>
      <div className={css.title}>{title}</div>
      <div className={css.view}>
        <a href={fileUrl} rel="noreferrer">
          <Img alt="" src="/icons/eye-open.svg" />
        </a>
      </div>
      <div className={css.download}>
        <a onClick={handleDownload}>
          <Img alt="" src="/icons/arrow-line-down.svg" />
        </a>
      </div>
    </div>
  )
}

export default PDFBox
